<template>
    <div>
        <nav-bar></nav-bar>
        <service-support-banner>
            星戟<br> 产品特点
        </service-support-banner>
        <div class="container-1646">
          <div class="flex-between-wrap Features-space">
            <div  class="Features" v-for="(item,index) in Features" :key="index">
              <img :src="require('../assets/images/service-support/Features'+index+'.png')"  class="Features-img"/>
              <div  class="Features-title">{{item.title}}</div>
              <div   v-for="(list,i) in item.description" :key="i" :class="item.description.length>1?'Features-description-circle':'Features-description'">
               {{list}}
              </div>
            </div>
          </div>
          
        </div>
        <div>
            <img src="../assets/images/service-support/minjie.png" alt="" class="img">
        </div>
        <div>
          <map-company></map-company>
        </div>   
        <Footer></Footer>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  　import { reactive, onMounted,toRefs ,ref,nextTick, computed } from 'vue'
import navBar from '@/components/NavBar'
import Footer from '@/components/Footer'
import ServiceSupportBanner from '@/components/ServiceSupportBanner'
import MapCompany from '@/components/map'
import { useRouter } from 'vue-router';
  export default {
    name: 'Home',
    components: {
      navBar,
      Footer,
      ServiceSupportBanner,
      MapCompany
    },
  
    setup() { 
        const router = useRouter()
      const useData = reactive({
        Features: [
          {
            title: "体小量轻",
            description: ['全闪存单元，空间占用节省50%，重量减轻70%','紧凑型模块结构，再节省30%的空间'],
          },
          {
            title: "绿色节能",
            description: ['全闪存架构使电力能耗降低70%，从而大幅降低数据中心的TCO总成本',' 智能分布式制冷单元，降低能耗约10% '],
          },
          {
            title: "安全可靠",
            description: ['数据不会丢失、复用无需等待，50年无需转存 '],
          },
          {
            title: "运行高效",
            description: ['全闪存网络架构使模块性能提升40倍，GPU框架助力各种超算场景 '],
          },
          {
            title: "智能维护",
            description: ['智能运维软件控制，全自动远程维护'],
          },
          {
            title: "方便兼容",
            description: ['兼容传统机房产品方便传统机房升级'],
          },
        ],
      
      })
      const goTo = (id) => {
      router.push({ path: '/Item', query: { id } })
      }
      const go = (path) => { 
      router.push({ path: path })
      }
    return {
        ...toRefs(useData),
      goTo,
      go
    }
  }
}
  </script>
  <style scoped>
.Features{
  width: 521px;
height: 526px;
background: #FFFFFF;
margin: 20px 0px;
box-shadow: 6px 0px 38px 2px rgb(249,247,255);
}
.Features-space{
  margin: 120px 0px;
}
.Features-title{
  font-size: 34px;text-align: center;padding: 0 0 30px;font-weight: bold;
}
.Features-description-circle,.Features-description{
  font-size: 20px;
  color: #999999;
  padding: 0px 56px 10px;
  position: relative;
}
.Features-description-circle::before{
    content: '';
    width: 6px;
height: 6px;
background: #C1C1C1;
border-radius: 50%;
position: absolute;
top: 12px;
left: 40px;

}
.Features-img{
  width: 196px;
  height: 201px;
  margin: 33px auto 40px;display: block;
}
.Features-specialty{
  text-align: center;
  font-size: 20px;
  color: #53B578;
}
</style>
  